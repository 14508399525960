import ErrorPage from "../pages/ErrorPage"
import LeadsPage from "../pages/LeadsPage"
import AnalyticsPage from "../pages/AnalyticsPage"
import FeedbackPage from "../pages/FeedbackPage";
import ProfilePage from "../pages/ProfilePage"
import LogsPage from "../pages/LogsPage"


function CenterSection(page) {
  // Render the correct page
  switch(page.page) {
    case 'leads':
      return (<LeadsPage />);
    case 'analytics':
      return (<AnalyticsPage />);
    case 'feedback':
      return (<FeedbackPage />);
    case 'profile':
      return (<ProfilePage />);
    case 'logs':
      return (<LogsPage />);
    default:
      return (<ErrorPage />);
  }
}

export default CenterSection;
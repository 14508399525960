import { useRef, useState } from "react";
import { ReactComponent as CollapseIcon } from '../img/collapse.svg'
import { ReactComponent as AnalyticsIcon } from '../img/chart.svg'
import { ReactComponent as ScraperIcon } from '../img/magnet-user.svg'
import { ReactComponent as SettingsIcon } from '../img/settings.svg'
import { ReactComponent as ProfileIcon } from '../img/user.svg'
import { ReactComponent as FeedbackIcon } from '../img/question.svg'
import { ReactComponent as LogsIcon } from '../img/logs.svg';
import NavButton from "./NavButton";
import Logo from '../img/logo-white.png'
import { user } from "../firebase";


export default function NavSection({ page, setPage }) {
  const [isNavHidden, setIsNavHidden] = useState(true);

  const adminUidsRef = useRef([
    'rUrTSPESzxcQG3Cjhj794PQPqu12', // Test acc
    'LfPrlG8u83grPjg6QPuTqKIVKRy2', // Kels
    'vexTD8j8wZNrAC7WIxDgUhzFAHn2', // Wally
    'riDPjGdkJJgFFYDWgTPeVnigF653', // Luke
    'aSsb8FSN02WYtTEJVf7mmmH9XMK2', // Trevor
  ])

  return (
    <nav className='p-2 h-full flex flex-col drop-shadow-lg'>
      <ul className="h-full flex flex-col justify-between gap-4 text-bone">
        <div className="flex flex-col gap-4">
          <li>
            <div className={`flex flex-row items-center gap-2 ${isNavHidden ? 'justify-center' : 'justify-between'}`}>
              <img src={Logo} className={`w-16 -m-3 ${isNavHidden ? 'hidden' : 'visible'}`} alt="Logo" />
              <h2 className={`text-lg ${isNavHidden ? 'hidden' : 'visible'}`}>RV Gumshoe</h2>
              <button className="bg-secondary rounded-md" onClick={() => setIsNavHidden(!isNavHidden)}>
                <CollapseIcon 
                  className={`w-8 h-8 fill-bone transition duration-300 ${isNavHidden ? 'rotate-180' : 'rotate-0'}`}
                />
              </button>
            </div>
          </li>
          <NavButton pageName='analytics' page={page} setPage={setPage} isNavHidden={isNavHidden}>
            {isNavHidden
            ? <AnalyticsIcon className="w-10 h-10 p-1" />
            : <p className="transition duration-200 px-2 py-1">Analytics</p>
            }
          </NavButton>
          <NavButton pageName='leads' page={page} setPage={setPage} isNavHidden={isNavHidden}>
            {isNavHidden
            ? <ScraperIcon className="w-10 h-10 p-1" />
            : <p className="transition duration-200 px-2 py-1">Leads</p>
            }
          </NavButton>
          {adminUidsRef.current.includes(user.uid) && (
            <NavButton pageName='logs' page={page} setPage={setPage} isNavHidden={isNavHidden}>
              {isNavHidden
              ? <LogsIcon className="w-10 h-10 p-1" />
              : <p className="transition duration-200 px-2 py-1">Leads</p>
              }
            </NavButton>
          )}
        </div>
        
        <div className="flex flex-col gap-4">
          <NavButton pageName='feedback' page={page} setPage={setPage} isNavHidden={isNavHidden}>
            {isNavHidden
            ? <FeedbackIcon className="w-10 h-10 p-1" />
            : <p className="transition duration-200 px-2 py-1">Feedback</p>
            }
          </NavButton>
          <NavButton pageName='profile' page={page} setPage={setPage} isNavHidden={isNavHidden}>
            {isNavHidden
            ? <ProfileIcon className="w-10 h-10 p-1" />
            : <p className="transition duration-200 px-2 py-1">Profile</p>
            }
          </NavButton>
          <NavButton pageName='settings' page={page} setPage={setPage} isNavHidden={isNavHidden}>
            {isNavHidden
            ? <SettingsIcon className="w-10 h-10 p-1" />
            : <p className="transition duration-200 px-2 py-1">Settings</p>
            }
          </NavButton>
        </div>
      </ul>
    </nav>
  );
}
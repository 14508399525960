export default function Button({ children, onClick, isActive, className }) {
  let style;
  if (isActive) {
    style = `text-bone bg-primary-light w-full px-4 py-1 rounded-sm drop-shadow-md transition`
  } else {
    style = `text-bone bg-primary w-full px-4 py-1 rounded-sm drop-shadow-md transition
             hover:bg-primary-dark`
  }

  return(
    <button onClick={onClick} className={style + ' ' + className} >{children}</button>
  );
}
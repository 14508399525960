import NavSection from "./NavSection";
import CenterSection from "./CenterSection";
import SigninPage from "../pages/SigninPage";
import { useState, useEffect } from "react";
import { auth, initializeUser, logEvent } from "../firebase";

function PageWrapper() {
  const [page, setPage] = useState('leads');
  const [userState, setUserState] = useState(null);
  const [loading, setLoading] = useState(true);

  // Use Firebase listener to check user authentication state
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      initializeUser().then((userData) => {
        if (!userData) {
          logEvent('login', { status: 'failed' });
          setUserState(null);
          setLoading(false);
          return;
        }

        logEvent('login', { status: 'success' });
        setUserState(userData);
        setLoading(false);
      });
    });

    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, []);

  // Show loading spinner or fallback while checking auth state
  if (loading) {
    return (
      <div className="bg-secondary w-full h-[100vh] flex items-center justify-center">
        <h1 className="text-bone text-2xl">Loading the site...</h1>
      </div>
    );
  }

  // If user is not authenticated, show the SigninPage
  if (!userState) {
    return <SigninPage user={userState} setUser={setUserState} />;
  }

  return (
    <section className="w-full h-screen overflow-hidden flex flex-row">
      <section className="w-fit max-w-fit min-w-fit relative h-full overflow-hidden bg-primary">
        <NavSection page={page} setPage={setPage} />
      </section>
      <section className="relative min-w-4/6 w-full h-full overflow-y-auto bg-secondary">
        <CenterSection page={page} />
      </section>
    </section>
  );
}

export default PageWrapper;

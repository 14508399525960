export default function ToggleButton({ callback, condition, tooltipActive=null, tooltipInactive=null, colorActive, colorInactive, colorText='bone', children }) {
  return (
    <div className="flex items-center justify-center">
      <div className="relative group">
        <button
          className={
            `rounded-md shadow-md p-1 transition group
            ${condition
              ? `bg-${colorActive}`
              : `bg-${colorInactive} text-${colorText} hover:bg-${colorActive}-light`
            }`
          }
          onClick={callback}
        >
          {children}
        </button>
        {tooltipActive && tooltipInactive && (
          <span className="text-center w-24 z-50 absolute top-8 left-1/2 transform -translate-x-1/2 px-2 py-1 text-xs text-white bg-black-light rounded-md hidden group-hover:block opacity-0 group-hover:opacity-100 transition-opacity duration-200">
            {condition ? tooltipActive : tooltipInactive}
          </span>
        )}
      </div>
    </div>
  )
}
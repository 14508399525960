import { useEffect, useRef, useState } from 'react';
import { user, db } from '../firebase';
import { ReactComponent as LogsIcon } from '../img/logs.svg';
import { ReactComponent as RefreshIcon } from '../img/refresh.svg';
import { collection, getDocs, query, where } from 'firebase/firestore';

export default function LogsPage() {
  // ==========================
  // State
  // ==========================
  const [org, setOrg] = useState(user.org);
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isRefreshDebounce, setIsRefreshDebounce] = useState(true);
  const [didFetch, setDidFetch] = useState(false);
  const [uniqueLogins, setUniqueLogins] = useState(0);
  const [listingsClaimed, setListingsClaimed] = useState(0);
  const [listingsArchived, setListingsArchived] = useState(0);
  const [daysAgo, setDaysAgo] = useState(0);

  // ==========================
  // Functions
  // ==========================

  async function fetchLogs() {
    const logsRef = collection(db, 'logs');

    const startOfDay = new Date(new Date(new Date().setDate(new Date().getDate() - daysAgo)).setHours(0, 0, 0, 0));

    const logsQuery = query(
      logsRef,
      where('org', '==', org),
      where('timestamp', '>=', startOfDay)
    );
    const logsSnapshot = await getDocs(logsQuery);

    const sortedLogs = logsSnapshot.docs
      .map((log) => log.data())
      .sort((a, b) => b.timestamp.seconds - a.timestamp.seconds || b.timestamp.nanoseconds - a.timestamp.nanoseconds);

    calcListingsClaimed(sortedLogs);
    calcListingsArchived(sortedLogs);
    calcUniqueLogins(sortedLogs);

    setLogs(sortedLogs);
    setLoading(false);
    setDidFetch(true);

    
    const timeoutId = setTimeout(() => {
      setIsRefreshDebounce(false);
      setDidFetch(false);
    }, 4000);
    
    return () => clearTimeout(timeoutId);
  }

  function handleRefreshClick() {
    setIsRefreshDebounce(true);
    fetchLogs();
  }

  function calcListingsClaimed(l) {
    setListingsClaimed(l.filter(log => log.event === 'claim').length - l.filter(log => log.event === 'unclaim').length)
  }

  function calcListingsArchived(l) {
    setListingsArchived(l.filter(log => log.event === 'archive').length - l.filter(log => log.event === 'unarchive').length);
  }

  function calcUniqueLogins(l) {
    // return the number of unique users that have logged in
    const uniqueUsers = l.reduce((acc, log) => {
      if (!acc.includes(log.user)) {
        acc.push(log.user);
      }
      return acc;
    }, []);

    setUniqueLogins(uniqueUsers.length);
  }

  // ==========================
  // useEffects
  // ==========================

  // ==== Update org state when user.org changes ====
  useEffect(() => {
    if (user.org !== org) {
      setOrg(user.org);
    }

    fetchLogs();
  }, [daysAgo]);

  return (
    <section id="logs-page" className="w-full h-[100vh] p-2">
      {loading
        ? (
          <div className="flex items-center justify-center h-full">
            <p className="text-4xl text-bone font-semibold">Loading logs...</p>
          </div>
        ) : (
          <div className="flex flex-col flex-nowrap text-bone">
            <div className='flex items-center justify-between px-4 mb-4'>
              <div className='flex items-center gap-4 ml-4'>
                <h1 className="text-center text-4xl font-semibold">{user.org === 'johnsonrv' ? 'Johnson RV' : user.org}</h1>
                <LogsIcon className="w-16 h-16 fill-bone" />
              </div>

              <div className='ring-1 ring-primary rounded-lg'>
                <button
                  className={
                    `py-1 px-2 transition-colors font-semibold rounded-tl-lg rounded-bl-lg
                    ${daysAgo === 0 
                      ? 'bg-tertiary text-secondary cursor-default'
                      : 'bg-secondary text-bone hover:bg-tertiary hover:text-secondary'}`
                    }
                    onClick={() => {
                      setDaysAgo(0);
                    }}
                >
                  Today
                </button>
                <button
                  className={
                    `py-1 px-2 transition-colors font-semibold
                    ${daysAgo === 1 
                      ? 'bg-tertiary text-secondary cursor-default'
                      : 'bg-secondary text-bone hover:bg-tertiary hover:text-secondary'}`
                    }
                    onClick={() => {
                      setDaysAgo(1);
                    }}
                >
                  Yesterday
                </button>
                <button
                  className={
                    `py-1 px-2 transition-colors font-semibold
                    ${daysAgo === 7 
                      ? 'bg-tertiary text-secondary cursor-default'
                      : 'bg-secondary text-bone hover:bg-tertiary hover:text-secondary'}`
                    }
                    onClick={() => {
                      setDaysAgo(7);
                    }}
                >
                  Last 7 days
                </button>
                <button
                  className={
                    `py-1 px-2 transition-colors font-semibold rounded-tr-lg rounded-br-lg
                    ${daysAgo === 30 
                      ? 'bg-tertiary text-secondary cursor-default'
                      : 'bg-secondary text-bone hover:bg-tertiary hover:text-secondary'}`
                    }
                    onClick={() => {
                      setDaysAgo(30);
                    }}
                >
                  Last 30 days
                </button>
              </div>
            </div>

            {/* Important Info */}
            <div className='flex flex-nowrap mb-4 w-full items-center justify-center gap-4'>
              <div className='flex flex-col flex-grow bg-primary rounded-lg items-center text-center p-2'>
                <h3 className='text-lg'>Unique logins</h3>
                <p className='text-4xl'>{uniqueLogins}</p>
              </div>
              <div className='flex flex-col flex-grow bg-primary rounded-lg items-center text-center p-2'>
                <h3 className='text-lg'>Listings claimed</h3>
                <p className='text-4xl'>{listingsClaimed}</p>
              </div>
              <div className='flex flex-col flex-grow bg-primary rounded-lg items-center text-center p-2'>
                <h3 className='text-lg'>Listings archived</h3>
                <p className='text-4xl'>{listingsArchived}</p>
              </div>
            </div>

            <div className='relative'>
              {/* Refresh Button */}
              <div className='absolute top-2 right-2 flex gap-2 items-center'>
                <p className={`${didFetch ? 'opacity-100' : 'opacity-0 pointer-events-none'} transition-opacity duration-300 bg-c-green-light px-1 rounded-lg text-primary font-semibold`}>Logs updated!</p>
                <button
                  className={`${isRefreshDebounce && 'opacity-50 pointer-events-none'} transition-transform hover:rotate-180 duration-300`}
                  onClick={handleRefreshClick}
                >
                  <RefreshIcon className='w-6 h-6' />
                </button>
              </div>

              {/* Logs Table */}
              <table className='min-w-full divide-y divide-secondary mb-2'>
                <thead className='bg-primary'>
                  <tr>
                    <th scope="col" className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>Timestamp</th>
                    <th scope="col" className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>User</th>
                    <th scope="col" className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>Event</th>
                    <th scope="col" className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>Details</th>
                  </tr>
                </thead>
      
                <tbody className='bg-primary divide-y divide-secondary'>
                  {logs.map(log => {
                    const details = log.details ? JSON.stringify(log.details).replaceAll(',', ', ').replaceAll(':', ': ') : '';
                    return (
                      <tr key={`log-${log.user}-${log.timestamp.seconds}-${log.timestamp.nanoseconds}`}>
                        <td className='px-6 py-4 whitespace-nowrap'>{log.timestamp.toDate().toLocaleString()}</td>
                        <td className='px-6 py-4 whitespace-nowrap'>{log.fullName}</td>
                        <td className='px-6 py-4'>{log.event}</td>
                        <td className='px-6 py-4'>{details}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )
      }
    </section>
  )
}
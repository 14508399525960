import { useState, useRef, useEffect } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import filterConfig from '../config/filterConfig';
import Select from 'react-select';

export function CheckboxFilter({ settingName, filters, setFilters, startOpen = false }) {
  const [isOpen, setIsOpen] = useState(startOpen);
  const optionsRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState('0px');

  // Handle checkbox change event
  const handleCheckboxChange = (value) => {
    setFilters(prevFilters => {
      const updatedFilters = { ...prevFilters };
      const currentArray = updatedFilters[settingName] || []; // Default to empty array

      // Check if the value already exists in the array
      if (currentArray.includes(value)) {
        // Remove value if it's already present
        updatedFilters[settingName] = currentArray.filter((v) => v !== value);
      } else {
        // Add value if it's not present
        updatedFilters[settingName] = [...currentArray, value];
      }

      return updatedFilters;
    });
  };

  // Get options from filterConfig
  const options = filterConfig[settingName]?.opts || [];

  // Effect to set container height based on open/closed state
  useEffect(() => {
    if (isOpen) {
      setContainerHeight(`${optionsRef.current.scrollHeight}px`);
    } else {
      setContainerHeight('0px');
    }
  }, [isOpen]);

  return (
    <div
      className={
        `${(filters[settingName] !== null && filters[settingName]?.length > 0) ? 'ring-1 ring-tertiary' : ''}
        w-full flex flex-col items-start shadow-lg drop-shadow-md bg-primary px-2 py-1 rounded-md -z-10`
      }
    >
      <button className="w-full flex items-center justify-start" onClick={() => setIsOpen(!isOpen)} style={{ cursor: 'pointer' }}>
        <p>{filterConfig[settingName]?.header}</p>
        <ChevronDownIcon className={`w-6 h-6 transition-all duration-200 -rotate-90 ${isOpen ? 'rotate-0' : ''}`} />
      </button>
      <div
        ref={optionsRef}
        className="overflow-hidden transition-all duration-300"
        style={{ height: containerHeight }}
      >
        <div className="flex flex-col gap-1 p-1 text-sm">
          {options.map((opt) => (
            <label key={opt.value} className="flex gap-1 items-center">
              <input
                type="checkbox"
                checked={filters[settingName]?.includes(opt.value) || false}
                onChange={() => handleCheckboxChange(opt.value)}
              />
              {opt.label}
            </label>
          ))}
        </div>
      </div>
    </div>
  );
}


export function MinMaxFilter({ settingName, filters, setFilters, startOpen = false }) {
  const [isOpen, setIsOpen] = useState(startOpen);
  const [minValue, setMinValue] = useState(filters[settingName]?.min || null);
  const [maxValue, setMaxValue] = useState(filters[settingName]?.max || null);
  const optionsRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState('0px');
  const debounceTimeoutRef = useRef(null); // Ref to store the timeout ID

  const handleMinChange = (e) => {
    const value = e.target.value === '' ? null : Number(e.target.value);
    setMinValue(value);

    // Clear the previous timeout if it exists
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    // Set a new timeout
    debounceTimeoutRef.current = setTimeout(() => {
      updateFilters(value, maxValue);
    }, 500); // 0.5 second debounce
  };

  const handleMaxChange = (e) => {
    const value = e.target.value === '' ? null : Number(e.target.value);
    setMaxValue(value);

    // Clear the previous timeout if it exists
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    // Set a new timeout
    debounceTimeoutRef.current = setTimeout(() => {
      updateFilters(minValue, value);
    }, 500); // 0.5 second debounce
  };

  const updateFilters = (min, max) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [settingName]: { min, max },
    }));
  };

  // Effect to set container height based on open/closed state
  useEffect(() => {
    if (isOpen) {
      setContainerHeight(`${optionsRef.current.scrollHeight}px`);
    } else {
      setContainerHeight('0px');
    }

    // Cleanup function to clear the timeout when component unmounts
    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, [isOpen]);

  return (
    <div
      className={
        `${(minValue !== null || maxValue !== null) ? 'ring-1 ring-tertiary' : ''}
        w-full flex flex-col items-start shadow-lg drop-shadow-md bg-primary px-2 py-1 rounded-md -z-10`
      }
    >
      <button className="w-full flex items-center justify-start" onClick={() => setIsOpen(!isOpen)} style={{ cursor: 'pointer' }}>
        {filterConfig[settingName]?.header}
        <ChevronDownIcon className={`w-6 h-6 transition-all duration-200 -rotate-90 ${isOpen ? 'rotate-0' : ''}`} />
      </button>
      <div
        ref={optionsRef}
        className="overflow-hidden transition-all duration-300"
        style={{ height: containerHeight }}
      >
        <div className="w-full flex flex-row gap-2">
          <label className="text-sm">
            Min:
            <input
              type="number"
              value={minValue !== null ? minValue : ''}
              onChange={handleMinChange}
              className="w-full text-primary text-sm p-1 rounded-md"
            />
          </label>
          <label className="text-sm">
            Max:
            <input
              type="number"
              value={maxValue !== null ? maxValue : ''}
              onChange={handleMaxChange}
              className="w-full text-primary text-sm p-1 rounded-md"
            />
          </label>
        </div>
      </div>
    </div>
  );
}


export function SliderFilter({ settingName, filterConfig, filter, updateFilters }) {
  const settings = filterConfig[settingName]

  const [activeSliderItem, setActiveSliderItem] = useState(filter === 'owner' ? [0, 1, 0] : filter === 'dealer' ? [1, 0, 0] : [1, 0, 0]);

  function handleSliderClick(value) {
    setActiveSliderItem(activeItem => {
      const newActiveItem = [...activeItem];
      newActiveItem.fill(0)

      // Replace the value at the specified index
      if (value === 'owner') newActiveItem[1] = 1;
      else if (value === 'dealer') newActiveItem[2] = 1;
      else newActiveItem[0] = 1;

      updateFilters(settingName, value);
      
      return newActiveItem;
    });

  }

  let i = -1;

  return (
    <div className="w-full shadow-md p-2 rounded-md bg-primary">
      <p className={``}>{settings.header}</p>
      <div className="flex flex-row flex-nowrap text-sm justify-start gap-1">
        {settings.opts.map(opt => {
          i++;
          return (
            <button
              key={i}
              id={`${settingName}-opt-${i}`}
              onClick={() => handleSliderClick(opt.value)}
              className={`font-bold transition-all duration-200 mt-2 w-fit px-3 py-1 rounded-md ${activeSliderItem[i] === 1 ? 'bg-tertiary text-primary' : 'bg-primary'}`}
            >
              {opt.label}
            </button>
          );
        })}
      </div>
    </div>
  );
}

export function DropdownFilter({ settingName, filters, setFilters, startOpen = false }) {
  const [isOpen, setIsOpen] = useState(startOpen);
  const [shouldHideOverflow, setShouldHideOverflow] = useState(true);
  const [containerHeight, setContainerHeight] = useState('0px');
  const optionsRef = useRef(null);

  // Handle checkbox change event
  const handleDropdownChange = (value) => {
    setFilters(prevFilters => {
      const updatedFilters = { ...prevFilters };
      updatedFilters[settingName] = value ? value.map(opt => opt.value) : [];
      return updatedFilters;
    });
  };

  // Get options from filterConfig
  const options = filterConfig[settingName]?.opts || [];

  useEffect(() => {
    if (isOpen) {
      setContainerHeight(`${optionsRef.current.scrollHeight}px`);
      const timeoutId = setTimeout(() => setShouldHideOverflow(false), 300);
      return () => clearTimeout(timeoutId);
    } else {
      setContainerHeight('0px');
      // Delay hiding overflow until the collapse animation completes
      setShouldHideOverflow(true);
    }
  }, [isOpen, filters[settingName]]);

  return (
    <div
      className={
        `${(filters[settingName] !== null && filters[settingName]?.length > 0) ? 'ring-1 ring-tertiary' : ''}
        w-full flex flex-col items-start shadow-lg drop-shadow-md bg-primary px-2 py-1 rounded-md`
      }
    >
      <button
        className="w-full flex items-center justify-start"
        onClick={() => setIsOpen(!isOpen)}
        style={{ cursor: 'pointer' }}
      >
        <p>{filterConfig[settingName]?.header}</p>
        <ChevronDownIcon className={`w-6 h-6 transition-all duration-200 -rotate-90 ${isOpen ? 'rotate-0' : ''}`} />
      </button>
      <div
        id={`${settingName}-dropdown`}
        className={`w-full transition-all duration-300 ${shouldHideOverflow ? 'overflow-hidden' : ''}`}
        style={{ height: containerHeight }}
        ref={optionsRef}
      >
        <Select 
          isMulti
          className="w-full text-primary text-sm p-1 rounded-md transition duration-500"
          value={options.filter(opt => filters[settingName]?.includes(opt.value))}
          options={options}
          onChange={opt => handleDropdownChange(opt)}
        />
      </div>
    </div>
  );
}
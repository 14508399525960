import { useState } from "react";
import Button from "../components/Button";


export default function AnalyticsPage() {
  const [activeLocation, setActiveLocation] = useState(0)
  return(
    <div className="relative w-full h-fit flex flex-row gap-4 justify-center items-center p-2">
      <Button
        className='max-w-fit'
        isActive={activeLocation === 0}
        onClick={() => setActiveLocation(0)}
      >Overall</Button>
      <Button
        className='max-w-fit'
        isActive={activeLocation === 1}
        onClick={() => setActiveLocation(1)}
      >Sandy</Button>
      <Button
        className='max-w-fit'
        isActive={activeLocation === 2}
        onClick={() => setActiveLocation(2)}
      >Fife</Button>
      <Button
        className='max-w-fit'
        isActive={activeLocation === 3}
        onClick={() => setActiveLocation(3)}
      >Medford</Button>
    </div>
  )
}
import { useEffect, useState } from "react";
import { auth, user } from "../firebase";
import { signOut } from "firebase/auth";

export default function ProfilePage() {
  const [u, setU] = useState(user);

  useEffect(() => {
    setU(user);
  }, [user]);

  const handleSignout = () => {
    signOut(auth);
  }

  return (
    <div className='p-2 flex justify-center items-center h-full text-bone'>
      <div className="p-4 flex flex-col gap-4 bg-primary shadow-lg rounded-lg">
        <p><b>Full Name:</b> {u.fullName}</p>
        <p><b>Email:</b> {u.email} </p>
        <div className="w-full flex justify-center">
          <button onClick={handleSignout} className="w-fit px-2 py-1 bg-c-red hover:bg-c-red-dark font-semibold transition duration-200 rounded-lg">Sign Out</button>
        </div>
      </div>
    </div>
  )
}
import React, { useRef, useState } from 'react';
import { user } from '../firebase';
import emailjs from '@emailjs/browser';

export default function FeedbackPage() {
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState(false);
  const [feedbackType, setFeedbackType] = useState('feedback');
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    setSending(true);

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_FEEDBACK_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(() => {
        setSending(false);
        setSuccess(true);
      })
      .catch((error) => {
        console.error('Error sending email', error);
        setSending(false);
      });
  };

  const labelClass = 'your-label-class';
  const inputClass = 'bg-primary p-1 rounded-md';

  return (
    <div className='p-2 flex justify-center items-center h-full text-bone'>
      {success ? (
        <div className='font-bold text-xl bg-primary rounded-xl p-4 flex flex-col gap-2 items-center'>
          <p>Your feedback has been successfully sent!</p>
          <button
            className='bg-tertiary hover:bg-tertiary-dark rounded-lg text-primary py-1 px-2 w-fit'
            onClick={() => setSuccess(false)}
          >
            Send more feedback
          </button>
        </div>
      ) : sending ? (
        <div className='font-bold text-xl'>Sending...</div>
      ) : (
        <div className='text-bone w-2/3'>
          <p className='mb-1'>Feedback Type</p>
          <div className='flex gap-2 mb-4 justify-start font-bold'>
            <button
              className={`p-2 rounded-lg transition ${
                feedbackType === 'feedback'
                  ? 'bg-tertiary hover:bg-tertiary-dark text-primary'
                  : 'bg-primary hover:bg-primary-dark text-bone'
              }`}
              onClick={() => setFeedbackType('feedback')}
            >
              Feedback
            </button>
            <button
              className={`p-2 rounded-lg transition ${
                feedbackType === 'bug'
                  ? 'bg-tertiary hover:bg-tertiary-dark text-primary'
                  : 'bg-primary hover:bg-primary-dark text-bone'
              }`}
              onClick={() => setFeedbackType('bug')}
            >
              Report a Bug
            </button>
          </div>
          <form ref={form} onSubmit={sendEmail} className='flex flex-col gap-2'>
            {/* Hidden Fields */}
            <input
              type='text'
              name='from_name'
              value={user.fullName}
              className='hidden'
              readOnly
            />
            <input
              type='email'
              name='from_email'
              value={user.email}
              className='hidden'
              readOnly
            />

            {feedbackType === 'feedback' ? (
              <>
                <div className='flex flex-col gap-1'>
                  <label className={labelClass}>
                    What would you like to see improved?
                  </label>
                  <textarea name='improved_message' className={inputClass} />
                </div>
                <div className='flex flex-col gap-1'>
                  <label className={labelClass}>
                    What additional features would you like added?
                  </label>
                  <textarea name='added_message' className={inputClass} />
                </div>
                <div className='flex flex-col gap-1'>
                  <label className={labelClass}>
                    Was the data helpful to your searches?
                  </label>
                  <textarea name='data_message' className={inputClass} />
                </div>
              </>
            ) : (
              <>
                <div className='flex flex-col gap-1'>
                  <label className={labelClass}>
                    Describe what page you were on and what you were trying to
                    do.
                  </label>
                  <textarea name='intent_message' className={inputClass} />
                </div>
                <div className='flex flex-col gap-1'>
                  <label className={labelClass}>
                    If you can copy the error, please paste it here.
                  </label>
                  <textarea name='error_message' className={inputClass} />
                </div>
                <div className='flex flex-col gap-1'>
                  <label className={labelClass}>
                    If you took a screenshot, upload it here.
                  </label>
                  <input type='file' name='file' />
                </div>
              </>
            )}

            <input
              type='submit'
              value='Submit'
              className={`${inputClass} mt-2 px-2 w-fit bg-tertiary hover:bg-tertiary-dark text-primary font-bold cursor-pointer`}
            />
          </form>
        </div>
      )}
    </div>
  );
}

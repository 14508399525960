import LogoWork from '../img/logo-work.png'

export default function ErrorPage() {
  return (
    <div className="text-bone relative w-full h-full flex flex-col items-center justify-center gap-4">
      <img src={LogoWork} alt='working' className='w-48' />
      <h1 className="text-4xl">Error 404</h1>
      <h2>The page you are trying to reach is currently under development!</h2>
    </div>
  )
}
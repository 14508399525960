import Button from "./Button";
import { ReactComponent as Placeholder } from '../img/rv.svg';
import { ReactComponent as LeftArrow } from '../img/left-arrow.svg';
import { ReactComponent as RightArrow } from '../img/right-arrow.svg';
import { ReactComponent as Map } from '../img/map.svg';
import { ReactComponent as Eye } from '../img/eye.svg';
import { useState, useEffect, useMemo } from "react";

export default function AdCard({ listing, setCenter, formatTimeAgo }) {
  const [imageIndex, setImageIndex] = useState(0);

  // Filter images that contain '600x450' in the URL or fallback to all images
  const filteredImages = useMemo(() => {
    if (listing.platform === 'craigslist') return listing.image_urls?.filter(url => url.includes('600x450') || url.includes('450x600')) || [];
    return listing.image_urls || [];
  }, [listing.images]);

  // Preload the next image when the imageIndex changes
  useEffect(() => {
    if (filteredImages.length > 0) {
      const nextImage = new Image();
      nextImage.src = filteredImages[(imageIndex + 1) % filteredImages.length];
    }
  }, [imageIndex, filteredImages]);

  // Update image index for right arrow click
  const incImageIndex = () => {
    setImageIndex(prevImageIndex => (prevImageIndex + 1) % filteredImages.length);
  };

  // Update image index for left arrow click
  const decImageIndex = () => {
    setImageIndex(prevImageIndex => (prevImageIndex - 1 + filteredImages.length) % filteredImages.length);
  };

  // Function to truncate description
  const truncateDescription = (description, maxLength) => {
    if (!description) return 'No description available';
    return description.length > maxLength ? description.substring(0, maxLength) + '...' : description;
  };

  const handleMapClick = (listing) => {
    setCenter({ lat: Number(listing.latitude), lng: Number(listing.longitude) });

    const requestSection = document.getElementById('request-section');
    if (requestSection) {
      requestSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const numberFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <div id={listing.key} className="md:w-1/2 lg:w-1/4 flex-grow bg-primary rounded-md overflow-hidden flex flex-col drop-shadow-xl">
      <div id="image-wrapper">
        {filteredImages.length === 0 ? (
          <div className="relative pb-[66.67%] overflow-hidden">
            <div className="absolute h-full w-full flex items-center justify-center">
              <Placeholder />
            </div>
          </div>
        ) : (
          <div className="relative pb-[66.67%] overflow-hidden">
            <img id="top-image" className="absolute h-full w-full object-cover" src={filteredImages[imageIndex]} alt="Listing" />
            <button onClick={decImageIndex} className="hover:scale-110 hover:shadow-2xl transition absolute top-1/2 left-2 fill-primary bg-bone rounded-full w-6 h-6 flex items-center justify-center">
              <LeftArrow width='1rem' className="-translate-x-[1px]" />
            </button>
            <button onClick={incImageIndex} className="hover:scale-110 hover:shadow-2xl transition absolute top-1/2 right-2 fill-primary bg-bone rounded-full w-6 h-6 flex items-center justify-center">
              <RightArrow width='1rem' className="translate-x-[1px]" />
            </button>
          </div>
        )}
      </div>
      <div id="text-wrapper" className="p-4 text-bone flex-grow flex flex-col">
        <div className="flex flex-row no-wrap pb-1 gap-3 items-center">
          {listing.time_ago <= 4 &&
            <span className="text-sm bg-tertiary px-2 py-[1px] rounded-full">New</span> 
          }
          <span className="text-sm">{formatTimeAgo(listing.time_ago)}</span>
        </div>
        <h1 id="title" className="text-2xl pb-1">{listing.makemodel || 'No Title'}</h1>
        <h2 id="price" className="text-lg pb-2 text-c-green">{numberFormatter.format(listing.price) || 'N/A'}</h2>
        <p id="description" className="pb-4">{truncateDescription(listing.description, 100)}</p>
        <p id="location" className="text-sm mb-2 mt-auto">{`${listing.city}, ${listing.state}`}</p>
        <div className="flex flex-col gap-1">
          <Button className='hover:text-primary bg-secondary hover:bg-tertiary flex justify-center items-center gap-2' onClick={() => window.open(listing.url, "_blank")}>
            <Eye className='w-6 h-6' />
            <p>See on Craigslist</p>
          </Button>
          <Button onClick={() => handleMapClick(listing)} className='hover:text-primary bg-secondary hover:bg-tertiary flex justify-center items-center gap-2'>
            <Map className='w-6 h-6' />
            <p>See on Map</p>
          </Button>
        </div>
      </div>
    </div>
  );
}

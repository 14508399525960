import { useEffect, useRef, useState } from 'react';
import { CheckboxFilter, DropdownFilter, MinMaxFilter } from "../components/CollapsingFilters";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/20/solid";
import { ReactComponent as CloseIcon } from '../img/close.svg';
import { ReactComponent as StarIcon } from '../img/star.svg';
import { ReactComponent as TrashIcon } from '../img/trash.svg';
import blankFilter from '../config/blankFilter.json';
import { logEvent, db, user } from '../firebase';
import ToggleButton from './ToggleButton';
import { addDoc, collection, doc, getDocs, query, where, deleteDoc, updateDoc } from 'firebase/firestore';

// TODO: Fetch saved filters when component mounts, then only check when changing back to saved filters
// Then, change 'save' to 'update' and use update logic in handleSaveFilterClick

export default function FilterSection({ filters, setFilters }) {
  const [showFilter, setShowFilter] = useState(false);
  const [showSaved, setShowSaved] = useState(false);
  const [savedFilters, setSavedFilters] = useState([]);
  const [currentFilter, setCurrentFilter] = useState(null);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const formRef = useRef(null);
  const didMountRef = useRef(false);

  // =================================
  // Functions
  // =================================

  function toggleFilter() {
    setShowFilter(!showFilter);
    logEvent('toggle_filter', { showFilter: !showFilter });
  }

  async function handleSaveFilterClick(event) {
    event.preventDefault();

    const filterName = formRef.current[0].value;
    
    if (filterName === '') {
      alert('Please make a filter name!');
      return;
    }

    if (filterName === 'filter') {
      alert("Let's get a little more creative -_-");
      return;
    }

    const filterCollectionRef = collection(db, 'filters');

    if (shouldUpdate) {
      try {
        const filterName = formRef.current[0].value;
        const filterCollectionRef = collection(db, 'filters');
    
        // Query the filters collection to find the document with the specified name
        const existingFilterQuery = query(filterCollectionRef, where('name', '==', filterName));
        const querySnapshot = await getDocs(existingFilterQuery);
    
        // Loop through the query results and update each matching document
        querySnapshot.forEach(async (document) => {
          const docRef = doc(db, 'filters', document.id); // Get a reference to the document by ID
          await updateDoc(docRef, {
            // Update with the new fields you want to save
            filterConfig: filters,
            updatedAt: new Date() // Optional: add a timestamp to track updates
          });
          
          console.log('Filter successfully updated');
          logEvent('update_filter', { filterName });
          
          // Update local state or UI as needed
          setSavedFilters((prev) =>
            prev.map((f) => f.name === filterName ? { ...f, filterConfig: filters } : f)
          );
        });
      } catch (e) {
        console.error('Error updating document: ', e);
      }
    } else {
      try {
        addDoc(filterCollectionRef, {
          name: filterName,
          uid: user.uid,
          fullName: user.fullName,
          org: user.org,
          filterConfig: filters
        }).then(() => {
          console.log('Filter successfully saved');
          logEvent('save_filter', { filterName: filterName });
          setSavedFilters(prev => [...prev, { name: filterName, uid: user.uid, fullName: user.fullName, org: user.org, filterConfig: filters }]);
        });
      } catch (e) {
        console.error('Error adding document: ', e);
      }
    }
  }

  function handleLoadClick(filter) {
    setFilters(filter.filterConfig);
    setCurrentFilter(filter);
    setShowSaved(false);
  }

  function handleDeleteClick(filter) {
    const colRef = collection(db, 'filters');
    const filterQuery = query(colRef, where('name', '==', filter.name));
  
    getDocs(filterQuery)
      .then((querySnapshot) => {
        querySnapshot.forEach((document) => {
          const docRef = doc(db, 'filters', document.id); // Get document reference by ID
          deleteDoc(docRef)
            .then(() => {
              console.log('Document successfully deleted');
              logEvent('delete_filter', { filterName: filter.name });
              setSavedFilters((prev) => prev.filter((f) => f.name !== filter.name));
            })
            .catch((error) => {
              console.error('Error removing document: ', error);
            });
        });
      })
      .catch((error) => {
        console.error('Error fetching documents: ', error);
      });
  }

  // =================================
  // UseEffect
  // =================================

  // Get saved filters from Firestore when showSaved is toggled
  useEffect(() => {
    const filtersRef = collection(db, 'filters');
    const filterQuery = query(filtersRef, where('org', '==', user.org));
    if (!didMountRef.current) {
      didMountRef.current = true;
      getDocs(filterQuery).then((querySnapshot) => {
        setSavedFilters(querySnapshot.docs.map(doc => doc.data()));
      });
    } else {
      if (showSaved) {
        getDocs(filterQuery).then((querySnapshot) => {
          setSavedFilters(querySnapshot.docs.map(doc => doc.data()));
        });
      }
    }
  }, []);

  // Update small text under filter button when filters change
  useEffect(() => {
    if (filters !== currentFilter?.filterConfig) setCurrentFilter(null);
  }, [filters]);

  return (
    <div>
      {showFilter && (
        <div
          className='w-[80vw] h-[100vh] absolute top-0 left-0 z-40'
          onClick={toggleFilter}
        />
      )}
      <div className='relative'>
        <button onClick={toggleFilter} className='flex justify-center text-bone hover:text-tertiary transition p-1 bg-secondary rounded-md shadow-md'>
          <AdjustmentsHorizontalIcon className="h-6 w-6" />
        </button>
        
        <div
          id='filter-picker'
          className={`
            ${showFilter ? 'opacity-100' : 'opacity-0 pointer-events-none'}
            transition-all h-[100vh] w-[25vw] min-w-80 -m-4 absolute top-0 right-0 bg-secondary p-2 z-40 flex flex-col gap-1 text-bone overflow-y-scroll shadow-xl drop-shadow-xl border-l-[1px]
          `}
        >
          <div className='mb-1 flex justify-between items-center'>
            <div className='flex justify-center'>
              <ToggleButton 
                condition={showSaved} 
                callback={() => {
                  setShowSaved(prev => !prev)
                  logEvent('get_saved_filters', { showSaved: !showSaved });
                }}
                colorActive={'tertiary'}
                colorInactive={'primary'}
              >
                <StarIcon className="w-6 h-6 transition" />
              </ToggleButton>
              <h2 className='text-2xl px-2'>{showSaved && 'Saved'} Filters</h2>
            </div>
            <button onClick={toggleFilter} className='p-2 rounded-full'>
              <CloseIcon className='h-4 w-4 fill-bone' />
            </button>
          </div>
          
          {currentFilter && (
            <p className='text-sm'>{currentFilter.name}</p>
          )}

          <hr className='m-2 mt-0' />

          {showSaved
            ? (
              <div className='w-full h-full bg-primary rounded-md p-2 flex flex-col overflow-y-auto gap-2'>
                {savedFilters.length === 0
                  ? <div className='font-bold text-xl text-center'>No saved filters!</div>
                  : savedFilters.map((filter, i) => (
                    <div key={i} className={`${filters === filter.filterConfig && 'ring-1 ring-tertiary'} shadow-md flex items-center justify-between py-1 px-2 bg-secondary rounded-md`}>
                      <div className='flex flex-col w-full'>
                        <h2 className='font-semibold'>{filter.name}</h2>
                        <p className='text-sm'>By: {filter.fullName}</p>
                      </div>
                      <div className='flex items-center gap-1'>
                        {user.uid === filter.uid && (
                          <button
                            className='p-1 group shadow-md rounded-md'
                            onClick={() => handleDeleteClick(filter)}
                          >
                            <TrashIcon className='transition w-6 h-6 stroke-primary fill-c-red-light group-hover:fill-c-red' />
                          </button>
                        )}
                        <button
                          className='shadow-md transition font-semibold bg-tertiary hover:bg-tertiary-dark px-2 py-1 rounded-md text-primary flex items-center justify-center gap-1 mr-1'
                          onClick={() => handleLoadClick(filter)}
                        >
                          Load
                        </button>
                      </div>
                    </div>
                  ))
                }
              </div>
            ) : (
              <>
                <DropdownFilter settingName="state" filters={filters} setFilters={setFilters} />
                <CheckboxFilter settingName="purveyor" filters={filters} setFilters={setFilters} />
                <MinMaxFilter settingName="price" filters={filters} setFilters={setFilters} />
                <MinMaxFilter settingName="year" filters={filters} setFilters={setFilters} />
                <MinMaxFilter settingName="miles" filters={filters} setFilters={setFilters} />
                <CheckboxFilter settingName="rv_type" filters={filters} setFilters={setFilters} />
                <CheckboxFilter settingName="drivetrain" filters={filters} setFilters={setFilters} />
                <CheckboxFilter settingName="transmission" filters={filters} setFilters={setFilters} />
                <CheckboxFilter settingName="condition" filters={filters} setFilters={setFilters} />
                <CheckboxFilter settingName="title_status" filters={filters} setFilters={setFilters} />
                <CheckboxFilter settingName="fuel_type" filters={filters} setFilters={setFilters} />
                <CheckboxFilter settingName="cylinders" filters={filters} setFilters={setFilters} />
                
                <hr className='m-2 mt-auto' />
                
                <form
                  id='save-filter-form'
                  ref={formRef}
                  onSubmit={(e) => handleSaveFilterClick(e)}
                  className='flex items-center p-1 min-h-12 gap-1 bg-primary rounded-md overflow-hidden'
                >
                  <input
                    required
                    className='w-full rounded-md p-1 m-1 text-primary font-semibold focus:outline-tertiary'
                    placeholder='Filter Name'
                    onChange={(e) => setShouldUpdate(savedFilters.some(filter => filter.name === e.target.value))}
                  />
                  <button
                    className='bg-tertiary hover:bg-tertiary-dark px-2 py-1 rounded-md text-primary flex items-center justify-center gap-1 mr-1'
                  >
                    <StarIcon className='w-4 h-4 fill-primary' />
                    <p className='font-semibold'>{shouldUpdate ? 'Update' : 'Save'}</p> 
                  </button>
                </form>
                <button type='button' className='bg-primary hover:bg-primary-dark px-2 py-1 rounded-md text-bone' onClick={() => setFilters(blankFilter)}>Reset Filters</button>
              </>
            )
          }
        </div>
      </div>
    </div>
  );
}

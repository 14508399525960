import { signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { auth, db } from "../firebase";
import { ReactComponent as Eye } from '../img/eye.svg';
import { ReactComponent as EyeSlash } from '../img/eye-slash.svg';
import logo from "../img/logo-white.png";


export default function SigninPage({ user, setUser }) {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [error, setError] = useState('');
  const [pageState, setPageState] = useState('signin'); // signin, signup, forgotPassword
  const [didSendReset, setDidSendReset] = useState(false);
  const formRef = useRef();

  useEffect(() => {
    setError('');
  }, [pageState])

  const handleForgotPasswordSubmit = (event) => {
    event.preventDefault();
    setError('');
    setDidSendReset(false);

    sendPasswordResetEmail(auth, formRef.current.email.value)
      .then(() => {
        setDidSendReset(true);
        setPageState('signin');
      })
      .catch((error) => {
        console.error('Error sending password reset email:', error);
        setError('Invalid email.');
      });
  }

  const handleSigninSubmit = (event) => {
    event.preventDefault();
    setError('');

    const email = formRef.current.email.value;
    const password = formRef.current.password.value;

    if (pageState === 'signin') {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          setUser(userCredential.user);
        })
        .catch((error) => {
          console.error('Error signing in:', error);
          setError('Invalid email or password.');
        });
    } else if (pageState === 'signup') {
      const rePassword = formRef.current.rePassword.value;

      if (rePassword !== password) {
        setError('Passwords do not match.');
        return;
      }

    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        setUser(user);
    
        try {
          // Create a Firestore document with user.uid as the document ID
          await setDoc(doc(db, 'users', user.uid), {
            uid: user.uid,
            fullName: formRef.current.fullName.value,
            email: user.email,
            org: 'johnsonrv', 
          });
    
          console.log('[+] User document created successfully');
        } catch (error) {
          console.error('[-] Error creating Firestore document:', error);
        }
      })
      .catch((error) => {
        console.error('[-] Error creating account:', error);
        setError(error.message);
      });
    }
  };

  return (
    <section className="relative h-screen bg-cover bg-center bg-signin overflow-hidden flex items-center justify-center">
      <div className="absolute opacity-85 bg-black top-0 left-0 w-screen h-screen z-10"></div>
      <div className="z-20 bg-primary text-bone p-4 rounded-lg w-1/3 min-w-96 shadow-lg drop-shadow-lg">
        <div className="flex items-center justify-center gap-2 mb-4">
          <img className="w-16 h-16 -m-3" src={logo} alt="logo" />
          <h1 className="text-3xl">RV Gumshoe</h1>
        </div>
        <h2 className="text-2xl mb-2 font-semibold">
          {pageState === 'signin' && 'Sign in'}
          {pageState === 'signup' && 'Create account'}
          {pageState === 'forgotPassword' && 'Reset Password'}
        </h2>
        {error && <p className="mb-1 text-c-red">{error}</p>}
        {didSendReset && <p className="mb-1 text-c-green">A code was sent to your email.</p>}
        { (pageState === 'signin' || pageState === 'signup')
          ? (
            <form ref={formRef} onSubmit={handleSigninSubmit} className="flex flex-col gap-2 mb-2">
              {pageState === 'signup' && (
                <div className="flex flex-col gap-1">
                  <label>Full Name</label>
                  <input required name="fullName" type="text" className="p-1 rounded-md text-primary" />
                </div>
              )}
              <div className="flex flex-col gap-1">
                <label>Email</label>
                <input required name="email" type="email" className="p-1 rounded-md text-primary" />
              </div>
              <div className="flex flex-col gap-1">
                <label>Password</label>
                <div className="w-full flex gap-1">
                  <input
                    required
                    className="p-1 rounded-md text-primary w-full"
                    type={passwordVisible ? 'text' : 'password'}
                    name="password"
                  />
                  <button
                    type="button"
                    onClick={() => setPasswordVisible((prev) => !prev)}
                    className="w-8 h-full p-1 bg-secondary rounded-lg transition hover:bg-secondary-dark"
                  >
                    {passwordVisible ? <EyeSlash className="w-6" /> : <Eye className="w-6" />}
                  </button>
                </div>
              </div>
              {pageState === 'signup' && (
                <div className="flex flex-col gap-1">
                  <label>Reenter Password</label>
                  <div className="w-full flex gap-1">
                    <input
                      required
                      className="p-1 rounded-md text-primary w-full"
                      type={passwordVisible ? 'text' : 'password'}
                      name="rePassword"
                    />
                    <button
                      type="button"
                      onClick={() => setPasswordVisible((prev) => !prev)}
                      className="w-8 h-full p-1 bg-secondary rounded-lg transition hover:bg-secondary-dark"
                    >
                      {passwordVisible ? <EyeSlash className="w-6" /> : <Eye className="w-6" />}
                    </button>
                  </div>
                </div>
              )}
              <input
                className="bg-tertiary hover:bg-tertiary-dark hover:cursor-pointer text-primary transition w-fit px-2 py-1 rounded-lg mt-1 self-center font-semibold"
                type="submit"
                value="Submit"
              />
            </form>
          ) : (
            <form ref={formRef} onSubmit={handleForgotPasswordSubmit} className="flex flex-col gap-2 mb-2">
              <div className="flex flex-col gap-1">
                <label>Email</label>
                <input required name="email" type="email" className="p-1 rounded-md text-primary" />
              </div>
              <input
                className="bg-tertiary hover:bg-tertiary-dark hover:cursor-pointer text-primary transition w-fit px-2 py-1 rounded-lg mt-1 self-center font-semibold"
                type="submit"
                value="Submit"
              />
            </form>
          )
        }
        <div className="flex items-center justify-evenly">
          {pageState === 'signin' ? (
            <button
              onClick={() => setPageState('signup')}
              className="hover:text-tertiary hover:cursor-pointer transition duration-100"
            >
              Create account
            </button>
          ) : (
            <button
              onClick={() => setPageState('signin')}
              className="hover:text-tertiary hover:cursor-pointer transition duration-100"
            >
              Sign in
            </button>
          )}
          <button
            onClick={() => setPageState('forgotPassword')}
            className="hover:text-tertiary hover:cursor-pointer transition duration-100"
          >
            Forgot password
          </button>
        </div>
      </div>
    </section>
  );
}
